<template>
  <div v-if="isView" class="qr-view"
       :class="{ 'qr-view-vip': isVip=='1', 'qr-view-text':isVip=='0'}">
    <div class="qr-view-content">
      <div class="qr-view-content-box"
           :class="{'box-small': qrScale > 0.65, 'box-max': qrScale < 0.5}"
           ref="infoBox">
        <div class="qr-view-content-block"
             :class="bookInfoType?'qrcode-bookInfoType':''">
          <div v-if="isVip=='1'"
               class="qr-view-content-VIP"></div>
          <div class="qr-view-content-box-title">
            <span>{{ $t('title.visitorCode') }}</span>
          </div>
          <div class="qr-view-content-box-qrcode-box">
            <div class="qr-view-content-box-qrcode">
              <img :src="imgQRcode"
                   width="100%"
                   alt=""
                   @click="imgQRcodePareview(imgQRcode)" />
              <div class="qrcode-cover"
                   v-if="show"></div>
            </div>
            <div class="qrcode-popup"
                 :class="this.$i18n.locale == 'en'?'qrcode-popup-en':''"
                 v-if="show"></div>
          </div>
          <template v-if="bookInfoType">
            <div class="qr-view-dashed">
              <span class="left"></span>
              <p class="text">{{ $t('qing-cha-kan-hui-yi-xin-xi') }}</p>
              <span class="right"></span>
            </div>
            <div class="qr-view-text">
              <p class="title">{{ $t('zhu-ti') }}<span class="center">{{bookInfo.caleTitle}}</span></p>
              <p class="title">{{ $t('hui-yi-shi') }}<span class="center">{{bookInfo.roomName}}</span></p>
              <p class="title">{{ $t('shi-jian') }}<span class="center">{{dateFormatting(bookInfo.beginTimeStamp,bookInfo.endTimeStamp)}}</span></p>
            </div>
          </template>
          <template v-else>
            <div class="qr-view-content-box-remind"
                 style="padding:0 32px;">
              <span>{{ $t('title.qrUsed') }}</span>
            </div>
          </template>
        </div>
        <div v-if="bookInfoType"
             class="qr-view-content-box-remind">
          <span>{{ $t('title.qrUsed') }}</span>
        </div>
      </div>
    </div>
    <div class="qr-view-bottom">
      <div class="qr-view-bottom-logo">
        <img :src="this.companyLogo != '' ? this.companyLogo : this.imgLogo"
             alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import { GetQr, GetLogo } from "@/api/guider";
import { getRequest } from "@/utils/common"
import { ImagePreview } from 'vant';
document.title = 'Vistiors'
export default {
  components: {},
  data () {
    return {
      id: this.$route.params.id,
      type: this.$route.params.type,
      lang: this.$route.params.lang,
      bookInfoType: false,
      bookInfo: {
        caleTitle: '',
        roomName: '',
        beginTimeStamp: 1661319001000,
        endTimeStamp: 1661320800000
      },
      imgQRcode: "",
      imgLogo: require("./images/mushiLogo.svg"),
      companyLogo: '',
      isVip: "",
      isView: false,
      show: false,
      qrScale: null,
      overdueQr: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOIAAADiCAIAAADccoyAAAAD7ElEQVR42u3ZMZLEIAwEQP//01xOsOXbAu0IWrmNQA3JPE/nGqtr+VrL97X8qxZTfjDFFFNMMcUUU0wxxRRTTDHFFFNMMcUUU0wxfc10ZFQlnVOb7z5lTDHFFFNMMcUUU0wxxRRTTDHFFFNMMcUUU0yXMK3Mk0IGc+FtyZkypphiiimmmGKKKaaYYooppphiiimmmGKKKaaXMA35YeXxYooppphiiimmmGKKKaaYYooppphiiimmmGKKKaa7meZncphiiimmmGKKKaaYYooppphiiimmmGKKKaaYHsa0sio7bH39Qtba0SGmmGKKKaaYYooppphiiimmmGKKKaaYYooppu+Z5tfywVz4VYspY4oppphiiilwmGKKKaaYYoqprzDFFFNMMcV0YjoOre/GmR/khLRRPU1MMcUUU0wxxRRTTDHFFFNMMcUUU0wxxRRTTN/uujQZCsmu8unceRqYYooppphiiimmmGKKKaaYYooppphiiimmmL5PoUIshpxjyL5CnpXlHWKKKaaYYooppphiiimmmGKKKaaYYooppphi+lumLbYdjiA/5GufQmGKKaaYYooppphiiimmmGKKKaaYYooppphiKq3J8FH5dhSfPKaYYooppphiiimmmGKKKaaYYooppphiiimmE9NTE5TKIw7xkS94RyeYYooppphiiimmmGKKKaaYYooppphiiimmdzLND3JCspDWTLuHYZhiiimmmGKKKaaYYooppphiiimmmGKKKaaYTkxb+wi5EsvXyj+o4oAKU0wxxRRTTDHFFFNMMcUUU0wxxRRTTDHFFNOpk5BxhgQ5+ZFMCLjiWAtTTDHFFFNMMcUUU0wxxRRTTDHFFFNMMcUU090p1Kk+8mOt1vnf5x9iiimmmGKKKaaYYooppphiiimmmGKKKaaYYjoxHYdWSEAVEhq1vhKYYooppphiiimmmGKKKaaYYooppphiiimmmP4rhbow8GjdYWW6VhzXYYoppphiiimmmGKKKaaYYooppphiiimmmGL6vpPWeVL+OCuZnpxCYYoppphiiimmmGKKKaaYYooppphiiimmmGJ6fYJS6eM5tHY8RphiiimmmGKKKaaYYooppphiiimmmGKKKaaYXsI0JAwL+WGIxS15GKaYYooppphiiimmmGKKKaaYYooppphiiimmUqgMi61V7ZgypphiiimmmGKKKaaYYooppphiiimmmGKKKaZLmLbedn4YduEd25JCYYoppphiiimmmGKKKaaYYooppphiiimmmF7JNL+Ks5AywflXongtTDHFFFNMMcUUU0wxxRRTTDHFFFNMMcUUU0wnpkOp+PoDdSMljyTHhkkAAAAASUVORK5CYII='
    };
  },
  computed: {
    code () {
      return this.$route.params.id
    }
  },
  watch: {
    code () {
      this.getData();
    }
  },
  mounted () {
    // 初始化
    this.init();
    this.qrScale = this.$refs.infoBox.offsetWidth / this.$refs.infoBox.offsetHeight
  },
  methods: {
    /**
     * @description 初始化
     */
    init () {
      this.getData();
      this.langChange()
    },
    langChange () {
      this.$i18n.locale = getRequest(location.href).lang
    },
    imgQRcodePareview (url) {
      ImagePreview({
        images: [url],
        overlayStyle: {
          backgroundColor: '#FFFFFF'
        }
      })
    },
    /**
     * @description 获取二维码数据
     */
    getData () {
      GetQr({
        code: this.id,
        type: this.type
      }).then((res) => {
        this.isVip = Number(res.isVip);
        if (res.bookInfo) {
          this.bookInfoType = true
          for (let i in this.bookInfo) {
            this.bookInfo[i] = res.bookInfo[i]
          }
        }
        if (!res.lastTime) {//判断是否过期
          this.imgQRcode = this.overdueQr
          this.show = true
          return
        }
        this.imgQRcode = res.qrBase64;
      }).catch((error) => {
        console.log(error)
      }).finally(() => {
        this.isView = true
      })
      GetLogo({
        code: this.id
      }).then(res => {
        if (res) this.companyLogo = res
      })
    },
    /**
     * @description 时间格式化
     */
    dateFormatting (start, end) {
      let s = new Date(start)
      let e = new Date(end)
      let date = s.getFullYear() + '/' + (s.getMonth() + 1) + '/' + s.getDate() + ' ' + s.getHours() + ':' + (s.getMinutes() < 10 ? '0' + s.getMinutes() : s.getMinutes()) + '-' + e.getHours() + ':' + (e.getMinutes() < 10 ? '0' + e.getMinutes() : e.getMinutes())
      return date
    }
  },
};
</script>

<style lang="scss">
.qr-view {
  position: absolute;
  text-align: center;
  width: 100%;
  min-height: 100vh;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  &.qr-view-vip {
    background: linear-gradient(128deg, #f8dfb7 0%, #e0c386 100%);
  }
  &.qr-view-text {
    background: linear-gradient(128deg, #b4d7ff 0%, #6baaff 100%);
  }
  .qr-view-content {
    height: calc(100vh - 60px);
    min-height: 670px;
    padding: 14px 12px 0;

    .qr-view-content-box {
      min-height: 660px;
      position: relative;
      height: 100%;
      padding: 0 18px 40px;
      background: url("./images/qrcode-background.png") no-repeat center;
      background-size: 100% 100%;
      border-radius: 6px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      &.box-max {
        padding-bottom: 50px;
      }
      &.box-small {
        padding-bottom: 20px;
        .qr-view-content-box-qrcode-box {
          padding: 0 20px;
        }
      }
      .qr-view-content-block {
        overflow: hidden;
        min-height: 560px;
        position: relative;
        margin-top: 36px;
        margin-bottom: 8px;
        padding: 0 0 24px;
        background-color: #ffffff;
        border-radius: 16px;
        box-shadow: 0px 7px 17px 0px rgba(0, 0, 0, 0.14);
        .qr-view-content-VIP {
          width: 100%;
          height: 48px;
          background-image: url(./images/VIP.png);
          background-position: 0 0;
          background-size: 100%;
        }
        .qr-view-content-box-title {
          margin: 0 auto;
          padding: 59px 0px 0;
          text-align: center;
          width: 210px;
          & > span {
            display: block;
            font-weight: 600;
            font-size: 24px;
          }
        }
        .qr-view-dashed {
          display: flex;
          align-items: center;
          justify-content: center;
          & > span {
            border: 1px dashed #dedede;
            border-bottom: none;
            width: calc(20% - 16px);
            height: 1px;
          }
          .text {
            width: 55%;
            color: #919296;
            font-size: 14px;
          }
        }
        .qr-view-text {
          padding: 28px 24px 0;
          .title {
            display: flex;
            justify-content: space-between;
            font-size: 14px;
            font-weight: 400;
            color: #3e3b3b;
            &:not(:last-child) {
              margin-bottom: 12px;
            }
            .center {
              font-size: 14px;
              font-weight: 600;
            }
          }
        }
        .qr-view-content-box-qrcode-box {
          position: relative;
          .qr-view-content-box-qrcode {
            position: relative;
            margin: 32px auto;
            width: 225px;
            height: 225px;
            text-align: center;
            & > img {
              position: relative;
              z-index: 2;
            }
          }
          .qrcode-popup {
            position: absolute;
            width: 176px;
            height: 176px;
            background-image: url(../../styles/images/index/Failure.svg);
            background-size: 100%;
            background-repeat: no-repeat;
            right: 10px;
            bottom: -44px;
            line-height: 260px;
            z-index: 101;
          }
          .qrcode-popup-en {
            background-image: url(../../styles/images/index/Failure-en.svg);
          }
          .qrcode-cover {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background-color: #ffffff99;
            z-index: 100;
          }
        }
      }
      .qrcode-bookInfoType {
        .qr-view-content-box-title {
          padding-top: 66px;
        }
        .qr-view-content-box-qrcode-box {
          .qr-view-content-box-qrcode {
            width: 192px;
            height: 192px;
          }
        }
      }
      .qr-view-content-box-remind {
        bottom: 20px;
        font-size: 12px;
        font-weight: 400;
        text-align: center;
        color: #919296;
      }
    }
  }
  .qr-view-bottom {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    .qr-view-bottom-logo {
      height: 40px;
      & > img {
        height: 100%;
      }
    }
  }
}
</style>
